<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" @click="propsCopy()">
        mdi-pencil
      </v-icon>
    </template>
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-sheet class="text-h6 left-color pa-2">露草会役員</v-sheet>
        <v-card-text>
          <v-row>
            <v-col align="center" cols="3">会長 </v-col>
            <v-col cols="9">
              <v-select
                v-model="kaicho"
                :items="userlist"
                item-text="name"
                item-value="id"
                :rules="rules"
                dense
                return-object
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center" cols="3">副会長</v-col>
            <v-col cols="9">
              <v-select
                v-model="fukukaicho"
                :items="userlist"
                item-text="name"
                item-value="id"
                :rules="rules"
                dense
                return-object
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center" cols="3">理事</v-col>
            <v-col cols="9">
              <v-select
                v-model="riji"
                :items="userlist"
                item-text="name"
                item-value="id"
                :rules="rules"
                dense
                return-object
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center" cols="3">会計</v-col>
            <v-col cols="9">
              <v-select
                v-model="kaikei"
                :items="userlist"
                item-text="name"
                item-value="id"
                :rules="rules"
                dense
                return-object
              ></v-select>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="4">
              <v-text-field
                type="number"
                class="right-input"
                v-model="newNendo"
                :rules="rules"
                label="年度"
              >
              </v-text-field
            ></v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> キャンセル </v-btn>
        <v-btn color="primary" text @click="send()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["editItem", "nendo"],
  data() {
    return {
      dialog: false,
      valid: false,
      editdata: [],
      rules: [(v) => !!v || "選択してください"],
      kaicho: {},
      fukukaicho: {},
      riji: {},
      kaikei: {},
      userlist: [],
      newNendo: "",
    };
  },

  methods: {
    propsCopy() {
      this.newNendo = this.nendo;
      this.editdata = this.editItem;
      this.getUserList();
      this.kaicho = { id: this.editdata[0].id, name: this.editdata[0].name };
      this.fukukaicho = {
        id: this.editdata[1].id,
        name: this.editdata[1].name,
      };
      this.riji = { id: this.editdata[2].id, name: this.editdata[2].name };
      this.kaikei = { id: this.editdata[3].id, name: this.editdata[3].name };
    },
    send() {
      if (this.$refs.form.validate()) {
        this.editdata[0].id = this.kaicho.id;
        this.editdata[0].name = this.kaicho.name;
        this.editdata[1].id = this.fukukaicho.id;
        this.editdata[1].name = this.fukukaicho.name;
        this.editdata[2].id = this.riji.id;
        this.editdata[2].name = this.riji.name;
        this.editdata[3].id = this.kaikei.id;
        this.editdata[3].name = this.kaikei.name;

        this.dialog = false;
        this.$emit(
          "my-click",
          this.$emit("data", {
            editdata: this.editdata,
            nendo: this.newNendo,
          })
        );
      }
    },
    getUserList() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-user-list")
        .then((response) => {
          this.userlist.splice(0);
          response.data.forEach((elem) => {
            this.userlist.push(elem);
          });
        });
    },
  },
};
</script>
<style>
.right-input input {
  text-align: right;
}
</style>