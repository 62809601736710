<template>
  <v-dialog v-model="dialog" max-width="300" @click="add()">
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small color="primary" fab dark v-bind="attrs" v-on="on">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-sheet class="text-h6 left-color pa-2">年度 追加</v-sheet>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="年度"
                :rules="rules"
                type="number"
                v-model="year"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-alert color="red lighten-2" dark v-show="msg">
            {{ msg }}
          </v-alert>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="clear()"> キャンセル </v-btn>
        <v-btn color="primary" text @click="add()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      year: "",
      dialog: false,
      valid: false,
      msg: "",
      rules: [
        (v) => !!v || "入力してください",
        (v) => /^[0-9]+$/.test(v) || "数字を入力してください",
      ],
    };
  },
  methods: {
    add() {
      if (this.$refs.form.validate()) {
        axios
          .post(process.env.VUE_APP_API_URL + "/regist-tsuyukusa-allows-year", {
            year: this.year,
          })
          .then((response) => {
            if (response.data.result == "NG") this.msg = response.data.msg;
            else {
              this.clear();
              this.$emit("my-click");
            }
          });
      }
    },
    clear() {
      this.dialog = false;
      this.year = "";
      this.$refs.form.reset();
    },
  },
};
</script>
<style>
.right-input input {
  text-align: right;
}
</style>