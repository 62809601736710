<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" @click="formatData"> mdi-pencil </v-icon>
    </template>
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-sheet class="text-h6 left-color pa-2">収支情報 修正</v-sheet>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="年度"
                readonly
                v-model="regist.nendo"
                suffix="年度"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                suffix="円"
                label="収入"
                :rules="rules"
                v-model="regist.in"
                @blur="regist.in = Number(regist.in).toLocaleString()"
                @focus="regist.in = regist.in.replaceAll(',', '')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                suffix="円"
                label="支出"
                :rules="rules"
                v-model="regist.out"
                @blur="regist.out = Number(regist.out).toLocaleString()"
                @focus="regist.out = regist.out.replaceAll(',', '')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                suffix="円"
                label="累積"
                :rules="rules"
                v-model="regist.ruiseki"
                @blur="regist.ruiseki = Number(regist.ruiseki).toLocaleString()"
                @focus="regist.ruiseki = regist.ruiseki.replaceAll(',', '')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-file-input
                v-model="newFile"
                :rules="fileRule"
                label="会計報告書を再アップロード"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                application/pdf"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-alert color="red lighten-2" dark v-show="msg">
            {{ msg }}
          </v-alert>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel"> キャンセル </v-btn>
        <v-btn color="primary" text @click="send()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["editData"],
  data() {
    return {
      msg: "",
      dialog: false,
      valid: false,
      regist: {
        nendo: this.editData.year,
        in: this.editData.in,
        out: this.editData.out,
        ruiseki: this.editData.ruiseki,
        uchiwake: this.editData.uchiwake,
      },
      newFile: null,
      rules: [
        (v) => !!v || "入力してください",
        (v) => /^[0-9,]+$/.test(v) || "数字を入力してください",
      ],
      fileRule: [
        (v) =>
          !v ||
          v.type ==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          v.type == "application/pdf" ||
          ".pdfまたは.xlsxのファイルを選択して下さい",
      ],
    };
  },
  methods: {
    send() {
      if (this.$refs.form.validate()) {
        this.regist.in = this.regist.in.replaceAll(",", "");
        this.regist.out = this.regist.out.replaceAll(",", "");
        this.regist.ruiseki = this.regist.ruiseki.replaceAll(",", "");

        const senddata = new FormData();
        senddata.append("nendo", this.regist.nendo);
        senddata.append("in", this.regist.in);
        senddata.append("out", this.regist.out);
        senddata.append("ruiseki", this.regist.ruiseki);
        senddata.append("files", this.newFile);

        axios
          .post(
            process.env.VUE_APP_API_URL + `/update-tsuyukusa-payments`,
            senddata,
            {
              headers: { "content-type": "multipart/form-data" },
            }
          )
          .then((response) => {
            this.msg = response.data.msg;
            this.dialog = false;
            this.$emit("my-click");
          });
      }
    },
    formatData() {
      this.regist.in = Number(this.regist.in).toLocaleString();
      this.regist.out = Number(this.regist.out).toLocaleString();
      this.regist.ruiseki = Number(this.regist.ruiseki).toLocaleString();
    },
    cancel() {
      this.regist.in = this.editData.in;
      this.regist.out = this.editData.out;
      this.regist.ruiseki = this.editData.ruiseki;
      this.dialog = false;
    },
  },
};
</script>
<style>
.right-input input {
  text-align: right;
}
</style>