<template>
  <v-dialog v-model="dialog" persistent max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" @click="editdata = editItem">
        mdi-pencil
      </v-icon>
    </template>
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-sheet class="text-h6 left-color pa-2">露草会について</v-sheet>
        <v-card-text>
          <v-row>
            <v-col>
              <v-textarea
              height="400"
                outlined
                v-model="editdata"
                :rules="rules"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> キャンセル </v-btn>
        <v-btn color="primary" text @click="send()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["editItem"],
  data() {
    return {
      dialog: false,
      valid: false,
      rules: [(v) => !!v || "入力してください"],
      editdata: "",
    };
  },

  methods: {
    send() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.$emit("my-click", this.editdata);
      }
    },
  },
};
</script>