<template>
  <v-dialog v-model="dialog" persistent max-width="600" @click="add()">
    <v-snackbar top v-model="snackbar" timeout="3000">
      登録しました。
    </v-snackbar>

    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small color="primary" fab dark v-bind="attrs" v-on="on">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-sheet class="text-h6 left-color pa-2"
          >{{ year }}年度支給情報 追加</v-sheet
        >
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="日付(yyyyMMdd)"
                :rules="rules"
                v-model="detail.date"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="詳細"
                v-model="detail.text"
                :rules="textRule"
                @keypress.enter="add()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-alert color="red lighten-2" dark v-show="msg">
            {{ msg }}
          </v-alert>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-btn text @click="close()"> 閉じる </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="add()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["year"],
  data() {
    return {
      snackbar: false,
      dialog: false,
      valid: false,
      detail: { date: "", text: "" },
      msg: "",
      rules: [
        (v) => !!v || "入力してください",
        (v) => /^[0-9]+$/.test(v) || "数字を入力してください",
        (v) => /^[0-9]{8}$/.test(v) || "yyyyMMddの形式で入力してください",
      ],
      textRule: [(v) => !!v || "入力してください"],
    };
  },
  methods: {
    add() {
      if (this.$refs.form.validate()) {
        axios
          .post(
            process.env.VUE_APP_API_URL + "/regist-tsuyukusa-allows-detail",
            {
              year: this.year,
              detail: {
                date: this.detail.date,
                text: this.detail.text,
              },
            }
          )
          .then((response) => {
            if (response.data.result == "NG") this.msg = response.data.msg;
            else {
              this.snackbar = true;
              this.$refs.form.reset();
              this.$emit("my-click");
            }
          });
      }
    },
    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$emit("my-click");
    },
  },
};
</script>
<style>
.right-input input {
  text-align: right;
}
</style>