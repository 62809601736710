<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small color="primary" fab dark v-bind="attrs" v-on="on">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-sheet class="text-h6 left-color pa-2">収支情報 追加</v-sheet>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="年度"
                :rules="rules"
                type="number"
                v-model="regist.nendo"
                suffix="年度"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                suffix="円"
                label="収入"
                :rules="rules"
                v-model="regist.in"
                @blur="regist.in = Number(regist.in).toLocaleString()"
                @focus="regist.in = regist.in.replaceAll(',', '')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                suffix="円"
                label="支出"
                :rules="rules"
                v-model="regist.out"
                @blur="regist.out = Number(regist.out).toLocaleString()"
                @focus="regist.out = regist.out.replaceAll(',', '')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                suffix="円"
                label="次年度繰越"
                :rules="rules"
                v-model="regist.ruiseki"
                @blur="regist.ruiseki = Number(regist.ruiseki).toLocaleString()"
                @focus="regist.ruiseki = regist.ruiseki.replaceAll(',', '')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-file-input
                v-model="regist.uchiwake"
                :rules="fileRule"
                label="会計報告書(.pdfまたは.xlsx)"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                application/pdf"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-alert color="red lighten-2" dark v-show="msg">
            {{ msg }}
          </v-alert>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="clear()" :disabled="loading">
          キャンセル
        </v-btn>
        <v-btn color="primary" text @click="send()" :loading="loading">
          登録
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      msg: "",
      loading: false,
      dialog: false,
      valid: false,
      regist: { nendo: "", in: "", out: "", ruiseki: "", uchiwake: null },
      rules: [
        (v) => !!v || "入力してください",
        (v) => /^[0-9,]+$/.test(v) || "数字を入力してください",
      ],
      fileRule: [
        (v) => !!v || "ファイルを選択してください",
        (v) =>
          !v ||
          v.type ==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          v.type == "application/pdf" ||
          ".pdfまたは.xlsxのファイルを選択して下さい",
      ],
    };
  },
  methods: {
    send() {
      if (this.$refs.form.validate()) {
        this.regist.in = this.regist.in.replaceAll(",", "");
        this.regist.out = this.regist.out.replaceAll(",", "");
        this.regist.ruiseki = this.regist.ruiseki.replaceAll(",", "");

        const senddata = new FormData();
        senddata.append("nendo", this.regist.nendo);
        senddata.append("in", this.regist.in);
        senddata.append("out", this.regist.out);
        senddata.append("ruiseki", this.regist.ruiseki);
        senddata.append("files", this.regist.uchiwake);

        axios
          .post(
            process.env.VUE_APP_API_URL + `/regist-tsuyukusa-payments`,
            senddata,
            {
              headers: { "content-type": "multipart/form-data" },
            }
          )
          .then((response) => {
            if (response.data.result == "NG") this.msg = response.data.msg;
            else {
              this.loading = true;
              setTimeout(() => {
                this.loading = false;
                this.clear();
                this.$emit("my-click");
              }, 3000);
            }
          });
      }
    },
    clear() {
      this.dialog = false;
      this.regist = { nendo: "", in: "", out: "", ruiseki: "", uchiwake: null };
      this.$refs.form.reset();
    },
  },
};
</script>
<style>
.right-input input {
  text-align: right;
}
</style>