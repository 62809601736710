<template>
  <v-container fluid class="down-top-padding">
    <v-snackbar top v-model="snackbar" timeout="3000"> {{ msg }} </v-snackbar>
    <v-row dense>
      <v-col cols="12" md="12">
        <v-card elevation="1">
          <v-sheet class="text-h6 pa-2 left-color"
            >露草会について
            <EditAbout
              v-if="authority.tsuyukusa"
              :editItem="about"
              @my-click="editAbout($event)"
            ></EditAbout>
          </v-sheet>
          <v-card-text class="detail">{{ about }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-card elevation="1">
          <v-sheet class="text-h6 pa-2 left-color"
            >露草会役員
            <EditUser
              v-if="authority.tsuyukusa"
              :editItem="userList"
              :nendo="nendo"
              @my-click="editUser($event)"
            ></EditUser>
          </v-sheet>
          <v-card-text class="pb-0"
            ><p v-for="user in userList" :key="user.id" class="mb-0">
              <v-chip class="ma-2 chip" label dark :color="user.iconcolor">
                {{ user.role }}
              </v-chip>
              {{ user.name }}
            </p></v-card-text
          >
          <v-card-text class="text-right">{{ nendo }}年度</v-card-text>
        </v-card></v-col
      >

      <v-col cols="12" md="8">
        <v-card elevation="1">
          <v-sheet class="text-h6 pa-2 left-color"
            >収支情報
            <AddPayments
              v-if="authority.tsuyukusa"
              @my-click="addPayment()"
            ></AddPayments>
          </v-sheet>
          <v-simple-table height="300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">年度</th>
                  <th class="text-right">収入</th>
                  <th class="text-right">支出</th>
                  <th class="text-right">次年度繰越</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <transition-group tag="tbody" name="list" v-if="payments[0]">
                <tr v-for="item in payments" :key="item.year">
                  <td>{{ item.year }}</td>
                  <td class="text-right">
                    {{ Number(item.in).toLocaleString() }}円
                  </td>
                  <td class="text-right">
                    {{ Number(item.out).toLocaleString() }}円
                  </td>
                  <td class="text-right">
                    {{ Number(item.ruiseki).toLocaleString() }}円
                  </td>
                  <td class="text-center">
                    <a
                      v-show="item.uchiwake"
                      :href="item.uchiwake"
                      target="_blank"
                      >内訳</a
                    >
                  </td>
                  <td>
                    <EditPayments
                      :editData="item"
                      @my-click="updatePayment"
                    ></EditPayments>

                    <Delete
                      v-if="authority.tsuyukusa"
                      @my-click="deletePayment(item.year)"
                    ></Delete>
                  </td>
                </tr>
              </transition-group>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet class="text-h6 pa-2 left-color" elevation="1"
          >慶弔見舞金支給情報
          <AddAllowYear
            v-if="authority.tsuyukusa"
            @my-click="addAllowYear()"
          ></AddAllowYear>
        </v-sheet>
        <v-col>
          <v-expansion-panels v-model="panel">
            <v-expansion-panel v-for="item in allows" :key="item.year">
              <v-expansion-panel-header
                class="text-subtitle-1"
                color="blue lighten-4"
                text
                >{{ item.year }}年度
                <div class="ml-2">
                  <AddAllowDetail
                    v-if="authority.tsuyukusa"
                    @my-click="getTsuyukusaInfo()"
                    :year="item.year"
                  ></AddAllowDetail>
                  <Delete
                    v-if="authority.tsuyukusa"
                    @my-click="deleteAllowsYear(item.year)"
                  ></Delete></div
              ></v-expansion-panel-header>
              <v-expansion-panel-content class="pt-5">
                <p v-for="(item, i) in item.detail" :key="i">
                  <v-chip small class="mr-3">{{ item.date | moment }}</v-chip
                  >{{ item.text }}
                  <Delete
                    v-if="authority.tsuyukusa"
                    @my-click="deleteAllow(item.id)"
                  ></Delete>
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel> </v-expansion-panels
        ></v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
// import _ from "lodash";
import EditAbout from "@/components/tsuyukusa/EditAbout";
import EditUser from "@/components/tsuyukusa/EditUser";
import AddPayments from "@/components/tsuyukusa/AddPayments";
import EditPayments from "@/components/tsuyukusa/EditPayments";
import Delete from "@/components/tsuyukusa/Delete";
import AddAllowYear from "@/components/tsuyukusa/AddAllowYear";
import AddAllowDetail from "@/components/tsuyukusa/AddAllowDetail";
import _ from "lodash";
import moment from "moment";

export default {
  name: "TsuyukusaPage",
  filters: {
    moment(value) {
      return moment(value).format("MM月DD日");
    },
  },
  data: () => ({
    authority: [],
    snackbar: false,
    msg: "",
    about: "",
    userList: [],
    nendo: "",
    payments: [],
    allows: [],
    panel: 0,
    changed: "",
  }),
  components: {
    EditAbout,
    EditUser,
    AddPayments,
    EditPayments,
    Delete,
    AddAllowYear,
    AddAllowDetail,
  },
  methods: {
    editAbout(event) {
      axios
        .post(process.env.VUE_APP_API_URL + "/update-tsuyukusa-about", {
          about: event,
        })
        .then(() => {
          this.msg = "更新しました。";
          this.getTsuyukusaInfo();
          this.changeFunc();
        });
    },
    editUser(event) {
      axios
        .post(process.env.VUE_APP_API_URL + "/update-tsuyukusa-userlist", {
          userlist: event.editdata,
          nendo: event.newNendo,
        })
        .then(() => {
          this.msg = "更新しました。";
          this.getTsuyukusaInfo();
          this.changeFunc();
        });
    },
    changeFunc() {
      this.snackbar = true;
      setTimeout(() => {
        this.changed = "";
      }, "2000");
    },
    addPayment() {
      this.msg = "登録しました。";
      this.getTsuyukusaInfo();
      this.changeFunc();
    },
    deletePayment(year) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-tsuyukusa-payment", {
          year: year,
        })
        .then((response) => {
          if (response.data.result == "OK") {
            this.msg = "削除しました。";
            this.getTsuyukusaInfo();
            this.changeFunc();
          }
        });
    },
    updatePayment() {
      this.msg = "更新しました。";
      this.getTsuyukusaInfo();
      this.changeFunc();
    },
    deleteAllowsYear(year) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-tsuyukusa-allows-year", {
          year: year,
        })
        .then((response) => {
          if (response.data.result == "OK") {
            this.msg = "削除しました。";
            this.getTsuyukusaInfo();
            this.changeFunc();
          }
        });
    },
    deleteAllow(id) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-tsuyukusa-allows", {
          id: id,
        })
        .then((response) => {
          if (response.data.result == "OK") {
            this.msg = "削除しました。";
            this.getTsuyukusaInfo();
            this.changeFunc();
          }
        });
    },
    addAllowYear() {
      this.msg = "登録しました。";
      this.getTsuyukusaInfo();
      this.changeFunc();
    },
    getTsuyukusaInfo() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-tsuyukusa")
        .then((response) => {
          this.about = response.data.about;
          this.userList.splice(0);
          response.data.userlist.forEach((elem) => {
            this.userList.push(elem);
          });
          this.nendo = response.data.nendo;
          this.payments.splice(0);
          response.data.payments = _.sortBy(
            response.data.payments,
            "year"
          ).reverse();
          response.data.payments.forEach((elem) => {
            this.payments.push(elem);
          });
          this.allows.splice(0);
          response.data.allows = _.sortBy(
            response.data.allows,
            "year"
          ).reverse();
          response.data.allows.forEach((elem) => {
            elem.detail = _.sortBy(elem.detail, "date").reverse();
            this.allows.push(elem);
          });
        });
    },
    getUserAuthority() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-user-authority")
        .then((response) => {
          this.authority = response.data.authority;
        });
    },
  },

  created() {
    this.getUserAuthority();
    this.getTsuyukusaInfo();
  },
};
</script>
<style>
.detail {
  white-space: pre-line;
}
table tr:nth-child(odd) td {
  background: #9696961a;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.chip {
  width: 70px;
  justify-content: center;
  align-items: center;
}
</style>
